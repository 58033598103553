<template>
  <main class="order-detail">
    <order-header
      :order="order"
      :itemInfo="itemInfo"
      :detail="detail"
      :oilOrder="oilOrder"
      :isOil="isOil"
      :images="images"
      :payInfo="payInfo"
      :namespaceGroup="namespaceGroup"
    />

    <new-coupon-code
      v-if="showCouponCode"
      :itemInfo="itemInfo"
      :detail="detail"
      :toCouponUse="toCouponUse"
      :payInfo="payInfo"
      :available="available"
      :isAfterMarket="isAfterMarket"
    />
    <div class="appointment-info order-card" v-if="show_appoint_box">
      <ul>
        <li><span>姓名：</span>{{ appoint_info.name }}</li>
        <li><span>联系电话：</span>{{ userInfo.user_phone }}</li>
        <li>
          <span>{{
            payInfo.product_code === "hljyk" ? "预约门店：" : "体检分院："
          }}</span>
          <div style="flex: 1">{{ appoint_info.hospital_name }}</div>
        </li>
        <li v-if="payInfo.product_code === 'hljyk'">
          <span>门店地址：</span>
          <div style="flex: 1">{{ payInfo.service_address }}</div>
        </li>
        <li><span>预约时间：</span>{{ appoint_date }}</li>
        <li v-if="payInfo.product_code === 'hljyk'">
          <span>预约状态：</span>{{ payInfo.appointment_status }}
        </li>
      </ul>
    </div>
    <div
      class="explain order-card"
      v-if="show_appoint_box && payInfo.product_code === 'hljyk'"
    >
      <div class="explain-title">取消规则</div>
      <switch-text :max-height="88" class="explain-text rich-text">
        <p>取消规则：</p>
        <p>
          1.预约确认中状态下，可在16:00前联系在线客服或电话客服取消订单，成功取消后，权益次数将恢复。
        </p>
        <p>
          2.已预约成功状态下不可自主取消，仅支持修改时间，如有疑问请联系在线客服或致电400-150-9669。
        </p>
        <p>使用规则：</p>
        <p>1.复制“商品券码”</p>
        <p>
          2.搜索关注“IDSO爱牙优选”公众号，点击“权益兑换”，弹出H5链接，进入链接，输入兑换码，兑换成功
        </p>
        <p>
          3.兑换成功后，领取的权益会保存在“IDSO爱牙优选”公众号-“会员商城”-“个人中心”-“卡”，显示
          为“橡树黑卡专享洁牙卡”
        </p>
        <p>4.到店须出示“橡树黑卡专享洁牙卡”二维码使用。</p>
      </switch-text>
    </div>
    <div class="order-card order-account" v-if="showAccount">
      充值账号
      <div class="account">
        {{ payInfo.top_up_account || payInfo.phone_number }}
      </div>
    </div>
    <div class="order-card order-account" v-if="payInfo.charge_game_region">
      充值大区
      <div class="account">{{ payInfo.charge_game_region }}</div>
    </div>
    <div v-if="itemInfo.instruction_img_url" class="explain order-card">
      <div class="explain-title">
        权益使用流程
        <router-link
          class="instruction-entry"
          v-if="itemInfo.detail_tutorial"
          :to="`/instruction?item_code=${payInfo.item_code}&benefit_type=${order.type}`"
          v-ans="{
            event_id: 'order_detail_instruction_button',
            order_number: detail.order_number,
            item_code: payInfo.item_code,
          }"
          >详细教程</router-link
        >
      </div>
      <div class="explain-text">
        <img :src="itemInfo.instruction_img_url" />
      </div>
    </div>
    <div v-if="explain" class="explain order-card">
      <div class="explain-title">权益介绍与说明</div>
      <switch-text :max-height="88">
        <div
          @click="handleUseGuide"
          class="explain-text rich-text"
          v-replace.day="order.coupon_days"
          v-html="explain"
        ></div>
      </switch-text>
    </div>
    <div class="order-card order-info">
      <div class="card-title">订单信息</div>
      <div class="order-number order-info-item">
        订单编号：{{ detail.order_number }}
        <span
          round
          size="mini"
          class="copy"
          v-throttle
          v-clipboard:copy="detail.order_number"
          v-clipboard:success="copySuccess"
          v-clipboard:error="copyError"
          v-ans="{
            event_id: 'order_detail_click_copyorder',
            order_id: detail.order_number,
            namespace,
          }"
          >复制</span
        >
      </div>
      <div class="order-time order-info-item">下单时间：{{ orderTime }}</div>
      <div class="order-time order-info-item" v-if="oilOrder.verify_datetime">
        核销时间：{{ verifyTime }}
      </div>
      <div class="order-time order-info-item" v-if="oilOrder.refund_datetime">
        退款时间：{{ refundTime }}
      </div>
      <div class="order-time order-info-item" v-if="isOil">
        开票方式：油站线下开票
      </div>
    </div>
    <div
      class="complaints-btn"
      @click="complaint"
      v-if="showComplaint"
      v-ans="{
        event_id: 'order_detail_complaint_button',
        order_id: detail.order_number,
        namespace,
      }"
    >
      对此单有疑问？去投诉 >
    </div>
    <div class="oil-tips" v-if="isOil">
      本服务由省心科技提供，如有问题请拨打
      <span>{{ $getConfigValue("SHENGXIN_OIL_PHONE") }}</span> 咨询
    </div>
    <new-order-footer
      :detail="order"
      :payInfo="payInfo"
      :available="available"
      :toCouponUse="toCouponUse"
      :fetchOrder="fetchOrderFn"
      :itemInfo="itemInfo"
      :isAfterMarket="isAfterMarket"
      :flow="flow"
      :toIndex="toIndex"
      :showIndex="showIndex"
    />
  </main>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import NewCouponCode from "./newCouponCode";
import moment from "dayjs";
import { getOrderDetail } from "@/apis";
import { getItemInfo } from "@/apis/home";
import ans from "@/utils/ans";
import dayjs from "dayjs";
import { checkCouponCanbeDisplay, getWorkFlow } from "@/apis/product";
import { getProjectDivision, getProjectDb } from "@/utils";
import OrderHeader from "./orderHeader";
import NewOrderFooter from "./newOrderFooter";
import SwitchText from "@/components/SwitchText";

export default {
  props: [
    "order",
    "fetchOrderFn",
    "detail",
    "oilOrder",
    "verifyInfo",
    "toIndex",
    "showIndex",
    "images",
  ],
  components: {
    NewCouponCode,
    OrderHeader,
    SwitchText,
    NewOrderFooter,
  },
  data() {
    return {
      count: 0,
      itemInfo: {},
      explain: "",
      appoint_info: {},
      show_appoint_box: false,
      isAfterMarket: false,
      flow: {},
    };
  },

  async mounted() {
    const { order_number } = this.$route.query;
    ans.pageView(this.$route.meta.page, {
      order_id: order_number,
      spu_name: this.order.name,
      namespace: this.namespace,
      order_status: this.order.status,
    });
    if (this.payInfo.biz_content) {
      this.appoint_info = JSON.parse(this.payInfo.biz_content);
      this.show_appoint_box = true;
    }
    if (this.payInfo.person_name) {
      this.appoint_info = {
        name: this.payInfo.person_name,
        hospital_name: this.payInfo.service_name,
        appointment_date: this.payInfo.appointment_date,
      };
      this.show_appoint_box = true;
    }
    if (this.payInfo.item_code) {
      const info = await getItemInfo({
        item_code: this.payInfo.item_code,
        benefit_type: this.detail.type,
        project_db: getProjectDb(this.namespace),
      });
      this.itemInfo = info;
      // 有售后标签才查询
      if (info.attributes?.afterMarket) {
        await this.fetchIsAfterMarket();
      }
      if (!this.hasCoupon) {
        this.explain = info.instruction || info.desc;
      }
      if (
        this.order.status === "unpaid" ||
        this.order.status === "confirming"
      ) {
        if (this.count < 10) {
          this.timer = setTimeout(this.fetchOrder, 1000);
        }
        this.count++;
      }
    }
    this.$nextTick(() => {
      this.listenClick();
    });
  },

  methods: {
    handleUseGuide(event) {
      const { special_info, product_name } = this.itemInfo;
      if (product_name === "瑞鹏宠物") {
        const target = event.target;
        if (target.localName === "a") {
          // 获取到a标签上的链接
          const url = target.getAttribute("href");
          const template_id = JSON.parse(special_info)?.template_id;
          console.log(url);
          const handleUrl = `${url}?template_id=${template_id}`;
          target.href = handleUrl;
        }
      }
    },
    listenClick() {
      const { order_number } = this.$route.query;

      const centerEl = document.querySelector(".rich-text");
      if (!centerEl) {
        return;
      }
      centerEl.addEventListener(
        "click",
        (e) => {
          let target = e.target;
          while (target !== centerEl) {
            if (target.nodeName !== "A") {
              target = target.parentNode;
            } else {
              break;
            }
          }
          if (target?.href) {
            // 转换a标签
            e.preventDefault();
            const temp = {
              order_number,
              order_name: this.detail.desc || this.detail.name,
            };
            const url = target
              .getAttribute("href")
              .replace(/{(\w+)}/g, (str, $) => {
                return temp[$];
              });
            if (url.startsWith("/")) {
              this.$router.push(url);
            } else {
              location.href = url;
            }
          }
        },
        false
      );
    },

    complaint() {
      this.$router.push(
        `/complaints?order_number=${this.detail.order_number}&entrance=订单详情页`
      );
    },
    // 是否售后中
    // 检查券码是否在售后中

    async fetchOrder() {
      const { order_number } = this.$route.query;
      const res = await getOrderDetail({ order_number });
      const data = res.data;
      this.$emit("update:order", data);
      const info = await getItemInfo({
        item_code: this.payInfo.item_code,
        benefit_type: data.type,
        project_db: getProjectDb(this.namespace),
      });
      this.itemInfo = info;
      if (!this.hasCoupon) {
        this.explain = info.instruction || info.desc;
      }
      if (data.status === "unpaid" || data.status === "confirming") {
        if (this.count < 30) {
          this.timer = setTimeout(this.fetchOrder, 2000);
        }
        this.count++;
      }
      return data;
    },

    copySuccess() {
      this.$toast("复制成功");
    },
    copyError() {
      this.$toast("复制失败,请手动复制");
    },
    async fetchIsAfterMarket() {
      const { order_number } = this.$route.query;
      if (!order_number) {
        return;
      }
      const [res, flow] = await Promise.all([
        checkCouponCanbeDisplay({
          order_number,
          project_division: getProjectDivision(this.namespace),
        }),
        getWorkFlow({
          order_number,
          project_division: getProjectDivision(this.namespace),
        }),
      ]);
      this.flow = flow.data;
      this.isAfterMarket = !res.data.result;
    },
  },
  computed: {
    ...mapState(["namespace", "source", "namespaceInfo", "userInfo"]),
    ...mapGetters(["namespaceGroup"]),
    showComplaint() {
      return this.$getConfigValue("SHOW_COMPLAINT_BTN");
    },
    showCouponCode() {
      return (
        this.order.status !== "refund" &&
        (this.payInfo.coupon_code || this.payInfo.secret_code)
      );
    },
    hasCoupon() {
      // 该租户是否有卡券模块，有卡券模块租户，隐藏兑换码，显示去使用按钮
      return this.$getConfigValue("HAS_COUPON");
    },
    available() {
      if (this.flow.flow_status === "updated") {
        return this.flow.coupon_enable;
      }
      if (this.detail.type === "addoil") {
        return true;
      }
      // 爱奇艺激活已使用链接
      if (this.payInfo.coupon_used === 1) {
        return false;
      }
      return this.detail.coupon_enable;
    },
    payInfo() {
      const pay_info = JSON.parse(this.detail.pay_info || "{}");
      if (this.verifyInfo.verify_code_type === 1) {
        pay_info.coupon_format = "link";
        pay_info.coupon_code = this.verifyInfo.verify_code;
      }
      if (this.verifyInfo.verify_code_type === 2) {
        pay_info.coupon_format = "qr_code";
        pay_info.coupon_code = this.verifyInfo.verify_code;
      }
      return pay_info;
    },
    // 跳转到卡券使用
    toCouponUse() {
      // 该租户是否有卡券模块，有卡券模块租户，隐藏兑换码，显示去使用按钮
      return (
        this.$getConfigValue("HAS_COUPON") &&
        (this.payInfo.top_up_type === 3 || this.payInfo.top_up_type === 4) &&
        (this.detail.status === "success" ||
          this.detail.status === "confirming")
      );
    },

    getPointsNumber() {
      if (this.detail.pay_platform === "joint") {
        if (this.payInfo.payment_info?.length) {
          return this.payInfo.payment_info[0].points;
        }
      }
      return this.detail.amount;
    },
    getAmount() {
      if (this.detail.pay_platform === "joint") {
        if (this.payInfo.payment_info[0]?.status === "success") {
          if (this.payInfo.payment_info.length > 1) {
            return this.payInfo.payment_info[1].cash_value;
          }
          return (
            this.detail.amount -
            (this.getPointsNumber * 0.15) / 40
          ).toFixed(2);
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    showAccount() {
      // 爱奇艺券码已使用，显示充值账号
      if (
        (this.payInfo.top_up_account || this.payInfo.phone_number) &&
        this.payInfo.coupon_used === 1
      ) {
        return true;
      }
      return (
        (this.payInfo.top_up_account || this.payInfo.phone_number) &&
        this.payInfo.top_up_type !== 3 &&
        this.payInfo.top_up_type !== 4 &&
        this.detail.type !== "appointment_akgb"
      );
    },
    orderTime() {
      return moment(this.detail.t_created).format("YYYY-MM-DD HH:mm:ss");
    },
    verifyTime() {
      return moment(this.oilOrder.verify_datetime).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    },
    refundTime() {
      return moment(this.oilOrder.refund_datetime).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    },

    appoint_date() {
      if (this.appoint_info.appointment_date) {
        return this.appoint_info.appointment_date;
      }
      const date = new Date(this.appoint_info.start_time * 1000);
      const end_date = new Date(this.appoint_info.end_time * 1000);
      let format = "YYYY年MM月DD日";
      const day = dayjs(date).format(format);
      let timeFormat1 = "HH:mm";
      const day1 = dayjs(date).format(timeFormat1);
      const day2 = dayjs(end_date).format(timeFormat1);
      return `${day} ${day1}-${day2}`;
    },
    isOil() {
      return this.detail.type === "addoil";
    },
  },

  beforeDestroy() {
    clearTimeout(this.timer);
  },
};
</script>

<style lang="less" scoped>
.order-detail {
  background: #fff;
  min-height: 100vh;
  box-sizing: border-box;
}

.order-card {
  margin: 0 12px 12px;
  border-radius: 6px;
  border: 1px solid #f1f1f1;
  padding: 16px;
}
.order-status {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  margin: 10px 0;
  padding: 13px 12px 10px;
}
.order-status2 {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  padding: 13px 12px 10px;
}
.order-status-tit {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.order-status-icon {
  font-size: 20px;
  margin-right: 10px;
}
.order-status-name {
  font-size: 18px;
  font-weight: bold;
  color: #222;
}

.order-product-main {
  display: flex;
  padding-top: 14px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.order-product-image {
  width: 80px;
  height: 80px;
  margin-right: 10px;
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 0;
}
.order-product-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.order-product-desc {
  line-height: 22px;
  font-size: 16px;
  font-weight: bold;
}
.order-product-facevalue {
  line-height: 18px;
  color: @text-gray-color;
  text-decoration: line-through;
}
.order-product-price {
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  &:last-child {
    padding-bottom: 10px;
  }
}

.price {
  font-size: @font-lg;
  font-weight: bold;
}

.sub {
  font-size: @font-sm;
  font-weight: normal;
}

.card-title {
  font-size: 13px;
  margin-bottom: 12px;
  color: #000;
  padding-top: 7px;
}
.explain {
  padding-top: 20px;
  padding-bottom: 20px;
}
.explain-title {
  line-height: 18px;
  font-size: 13px;
  color: #333;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  .instruction-entry {
    margin-left: auto;
    height: 21px;
    line-height: 21px;
    width: 59px;
    text-align: center;
    display: block;
    border: 1px solid #dcdcdc;
    border-radius: 21px;
    font-size: @font-xs;
    color: @text-primary-color;
    box-sizing: border-box;
  }
}
.explain-text {
  color: @text-gray2-color;
  line-height: 22px;
  white-space: pre-wrap;
  font-size: @font-sm;
  /deep/ img {
    max-width: 100%;
  }
}
.order-account {
  display: flex;
  justify-content: space-between;
  line-height: 18px;
  font-size: 13px;
  .account {
    font-weight: bold;
  }
}

.order-info-item {
  line-height: 22px;
  color: @text-gray2-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.order-info {
  padding-top: 10px;
  padding-bottom: 10px;
}
.copy {
  display: block;
  padding: 0 12px;
  line-height: 16px;
  color: @text-gray2-color;
  border: 1px solid currentColor;
  border-radius: 16px;
  box-sizing: border-box;
  font-size: @font-xs;
}
.order-status-errmsg {
  font-size: @font-sm;
  color: #333;
  margin: 9px 0 0 30px;
}
.appointment-info {
  color: #333;
  li {
    display: flex;
    line-height: 20px;
    & + li {
      margin-top: 12px;
    }
    span {
      width: 78px;
      margin-right: 20px;
    }
  }
}
.complaints-btn {
  text-align: center;
  color: #0091ff;
  font-size: 12px;
  padding: 10px 0 20px;
}
.oil-tips {
  text-align: center;
  color: #999;
  font-size: 12px;
  padding: 10px 0 20px;
  span {
    color: #0091ff;
  }
}
.desc-info {
  font-size: 12px;
  color: #999;
  font-weight: normal;
}
.member-price {
  color: #333;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    color: #ff5001;
    font-size: 20px;
  }
  span {
    font-size: 12px;
  }
}
.preferential-price {
  padding: 0 0 12px;
  .item {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #666;
    line-height: 18px;
    margin-top: 8px;
    span {
      color: #333;
      font-weight: bold;
    }
  }
  .item2 {
    text-align: right;
    color: #1a1a1a;
    margin-top: 16px;
    span {
      color: #ff5001;
      font-weight: bold;
    }
  }
}
</style>