<template>
  <div class="main">
    <div class="tag" v-if="config.show">
      可领取 {{ benefitInfo.amount + benefitInfo.amount_reserved }} 次
    </div>
    <div class="center">
      <img :src="benefitInfo.icon_url" alt="" :class="namespace" />
      <p class="name">{{ getRichText(benefitInfo.title) }}</p>
      <p class="price" v-if="namespace !== 'licheng7'">
        价值:¥ {{ faceValue }}
      </p>
      <div
        class="btn"
        :class="namespace"
        @click="jump"
        v-ans="{
          event_id: 'benefit_list_click',
          spu_id: benefitInfo.type,
          spu_name: benefitInfo.title,
        }"
      >
        {{ btnValue }}
      </div>
    </div>
    <section class="page-footer">
      <router-link class="order" to="/order"
        ><svg-icon :icon-class="computedOrderIcon" />{{
          orderText
        }}</router-link
      >
      <router-link class="service" to="/service"
        ><svg-icon :icon-class="computedServiceIcon" />{{
          serviceText
        }}</router-link
      >
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { getSkuByBenefitType } from "@/apis";

import { jsonSafeParse } from "@/utils";

export default {
  props: ["benefitList", "getRichText", "textConfig"],
  data() {
    return {
      benefitInfo: {},
      faceValue: "",
      config: {},
      footerTextConfig: {},
    };
  },

  computed: {
    ...mapState(["namespace"]),
    computedOrderIcon() {
      const icon = this.config?.order;
      if (icon) return icon;
      return "leiniao-order";
    },
    computedServiceIcon() {
      const icon = this.config?.service;
      if (icon) return icon;
      return "leiniao-service";
    },

    btnValue() {
      const btnConfig = jsonSafeParse(
        this.$getConfigValue("COMMON_REDEEM_BTN")
      );
      const isDisable = this.$getConfigValue("COMMON_PAGE_DISABLE");
      if (isDisable && !this.benefitInfo.amount) return "本权益已领完";
      return btnConfig[this.namespace] || "免费领取";
    },
    orderText() {
      return this.textConfig?.order || "我的订单";
    },
    serviceText() {
      return this.textConfig?.service || "在线客服";
    },
  },

  watch: {
    benefitList: {
      handler(val) {
        if (val?.length > 0) {
          this.getBenefit();
          this.benefitInfo = this.benefitList[0] || {};
        }
      },
      immediate: false,
    },
  },

  methods: {
    async jump() {
      this.$router.push(`/benefitDetail?benefit_type=${this.benefitInfo.type}`);
    },
    async getBenefit() {
      const type = this.benefitList[0]?.type;
      try {
        const res = await getSkuByBenefitType({
          benefit_type: type,
          source: "default",
          namespace: this.namespace,
        });
        const faceValue = jsonSafeParse(
          this.$getConfigValue("COMMON_PAGE_FACEVALUE")
        )[this.namespace];
        faceValue
          ? (this.faceValue = faceValue)
          : (this.faceValue = res.data[0]?.face_value);
      } catch (error) {
        console.log(error);
      }
    },
  },

  async mounted() {
    this.config =
      jsonSafeParse(this.$getConfigValue("COMMON_PAGE_CONFIG"))[
        this.namespace
      ] || {};
  },
};
</script>

<style scoped lang='less'>
.main {
  margin: 0 14px;
  height: 331px;
  background: #fff;
  border-radius: 13px;
  position: relative;
}
.tag {
  position: absolute;
  left: -7px;
  top: -1px;
  width: 93px;
  height: 31px;
  line-height: 24px;
  color: #fff;
  background: url("~@/assets/images/kukai_benefit_tag.png") no-repeat;
  background-size: 100%;
  font-size: 14px;
  text-align: center;
}

.center {
  font-family: PingFangSC, PingFang SC;
  padding-top: 35px;
  text-align: center;
  > img {
    width: 73px;
    height: 73px;
    margin: 0 auto;
    border-radius: 50%;
    &.KONKA_01,
    &.KONKA_03,
    &.KONKA_12,
    &.KONKA_PLUS,
    &.KONKA_ELM,
    &.KONKA_BD {
      border-radius: 0%;
    }
  }
  > .name {
    font-weight: 500;
    font-size: 17px;
    color: #333333;
    line-height: 24px;
    margin-top: 13px;
    text-align: center;
  }
  .price {
    font-weight: 500;
    font-size: 12px;
    color: rgb(115, 115, 115);
    line-height: 17px;
  }
  .btn {
    margin: 0 auto;
    width: 120px;
    height: 36px;
    background: linear-gradient(162deg, #ffb348 0%, #ff6326 100%);
    border-radius: 18px;
    margin-top: 19px;
    font-weight: 500;
    font-size: 15px;
    color: #ffffff;
    line-height: 35px;
    text-align: center;
    font-style: normal;
    &.haixin,
    &.cjyplus12,
    &.cjyxmly01,
    &.cjymy01,
    &.licheng7 {
      background: linear-gradient(270deg, #f02140 0%, #f8425c 100%);
      font-weight: 400;
    }
    &.hudun03 {
      background: linear-gradient(270deg, #f39378 0%, #e35a43 100%);
    }
    &.KONKA_01,
    &.KONKA_03,
    &.KONKA_12,
    &.KONKA_PLUS,
    &.KONKA_ELM,
    &.KONKA_BD {
      background: linear-gradient(270deg, #4580ff 0%, #0133a0 100%);
    }
    &.cjyxmly01,
    &.cjyplus12,
    &.cjymy01 {
      background: linear-gradient(270deg, #f39378 0%, #e35a43 100%),
        linear-gradient(270deg, #ff4713 0%, #ff7953 100%);
    }
  }
}

.page-footer {
  bottom: 20px;
  left: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  padding: 0 20px 0;
  box-sizing: border-box;

  .svg-icon {
    font-size: 20px;
    margin-right: 6px;
    color: #fd4c14;
    vertical-align: -4px;
  }
  .order,
  .service {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    border-radius: 42px;
    height: 42px;
    line-height: 42px;
    width: 145px;
    border: 1px solid #ebebeb;
    color: #202437;
    text-align: center;
    line-height: 40px;
    vertical-align: -4px;
    font-weight: bold;
  }
  .order {
    margin-right: 30px;
  }
}
</style>
