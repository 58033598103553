var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('van-dialog',{directives:[{name:"close",rawName:"v-close",value:(_vm.close),expression:"close"}],class:['login-dialog', _vm.className],attrs:{"value":_vm.value,"show-confirm-button":false}},[_c('div',{staticClass:"login-header"},[_c('div',{class:{ 'login-title': true },domProps:{"innerHTML":_vm._s(_vm.getTitle)}}),(_vm.subtitle)?_c('div',{staticClass:"login-desc"},[_vm._v(_vm._s(_vm.subtitle))]):_vm._e()]),_c('van-form',{staticClass:"login-form",attrs:{"show-error":false,"show-error-message":false},on:{"submit":_vm.onSubmit,"failed":_vm.onFailed}},[_c('van-field',{staticClass:"field-input",attrs:{"placeholder":"请输入手机号","rules":[
        {
          required: true,
          message: '请输入手机号',
        },
        {
          pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
          message: '请输入正确的11位手机号',
        } ]},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"phone"}}),_c('div',{staticClass:"get-code"},[_c('van-field',{staticClass:"field-input",attrs:{"placeholder":"请输入验证码","rules":[{ required: true, message: '请输入验证码' }]},model:{value:(_vm.code),callback:function ($$v) {_vm.code=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"code"}}),_c('div',{class:{ code: true, countDown: _vm.countDown > 0 },on:{"click":_vm.getVerifyCode}},[_vm._v(" "+_vm._s(_vm.countDown > 0 ? (_vm.countDown + "s后获取") : "获取验证码")+" ")])],1),_c('div',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.error))]),_c('div',{staticClass:"form-submit"},[_c('van-button',{attrs:{"round":"","size":"large","block":"","type":"info","native-type":"submit"}},[_vm._v("立即登录")])],1),(!_vm.needCheck)?_c('div',{staticClass:"login-agreement"},[_vm._v(" 登录即代表您同意 "),_c('span',{staticClass:"login-agreement-text",on:{"click":_vm.toAgreement}},[_vm._v(" 《橡树会员权益服务协议》 ")])]):_c('div',{staticClass:"login-agreement display-flex"},[_c('van-checkbox',{staticStyle:{"margin-right":"9px"},attrs:{"shape":"round","checked-color":"rgb(221, 100, 70)","icon-size":"16px"},model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}}),_vm._v(" 同意 "),_c('span',{staticClass:"login-agreement-text",on:{"click":_vm.toAgreement}},[_vm._v(" 《橡树会员权益服务协议》 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }