<template>
  <main class="coupon-detail">
    <van-empty v-if="showEmpty" description="券码不存在，请联系客服" />
    <div class="showcode-main" v-if="!loading && !showEmpty">
      <div v-if="status === 'used'" class="used"></div>
      <div v-if="status === 'expired'" class="expired"></div>
      <van-image
        lazy-load
        class="showcode-logo"
        fit="cover"
        :src="info.logo_url"
      />
      <div class="h40"></div>
      <div :class="`coupon-main coupon-main-${payInfo.coupon_format}`">
        <div class="coupon-name">{{ info.item_name }}</div>
        <div
          v-if="payInfo.coupon_format === 'kami'"
          class="coupon-code coupon-code-kami"
        >
          <div class="coupon-code-item">
            卡号：{{ payInfo.coupon_code }}
            <span
              class="copy"
              @click="
                {
                  copyCode(payInfo.coupon_code);
                }
              "
            ></span>
          </div>
          <div class="coupon-code-item">
            卡密：{{ payInfo.secret_code }}
            <span
              class="copy"
              @click="
                {
                  copyCode(payInfo.secret_code);
                }
              "
            ></span>
          </div>
        </div>
        <div v-if="payInfo.coupon_format === 'single'" class="coupon-code">
          <div class="coupon-code-item">
            {{ payInfo.coupon_code }}
            <span
              class="copy"
              @click="
                {
                  copyCode(payInfo.coupon_code);
                }
              "
            ></span>
          </div>
        </div>
        <div
          v-if="
            ['qr_code', 'bar_code', 'qr_bar_code'].includes(
              payInfo.coupon_format
            )
          "
          class="showcode-img"
        >
          <div class="coupon-bar-code">{{ payInfo.coupon_code }}</div>
          <qrcode-vue
            v-if="
              payInfo.coupon_format === 'qr_code' ||
              payInfo.coupon_format === 'qr_bar_code'
            "
            :value="coupon_code + ''"
            :size="120"
            level="H"
          />
          <barcode
            v-if="
              payInfo.coupon_format === 'bar_code' ||
              payInfo.coupon_format === 'qr_bar_code'
            "
            :value="coupon_code + ''"
            :width="1"
          >
            条形码生成失败，请联系客服
          </barcode>
        </div>
        <div class="coupon-expired">有效期至{{ order.coupon_expire_date }}</div>
      </div>
      <div class="showcode-tips" v-html="info.tips"></div>
      <div class="showcode-line">
        <span class="semicircle"></span>
        <span class="semicircle2"></span>
      </div>
      <div class="showcode-explain">
        <div class="showcode-explain-title">- 使用条款 -</div>
        <div class="showcode-explain-content" v-html="info.instruction"></div>
      </div>
    </div>
    <template v-if="status === 'unused' || payInfo.coupon_format === 'link'">
      <div class="h50"></div>
      <div class="code-btn-container">
        <div class="code-btn" v-if="status === 'unused'" @click="markUsed">
          标记为已使用
        </div>
        <div
          class="code-btn code-btn-primary"
          v-if="payInfo.coupon_format === 'link'"
          @click="toUse"
        >
          点击使用
        </div>
      </div>
    </template>
  </main>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import Barcode from "vue-barcode";
import * as copy from "copy-to-clipboard";
import { mapState } from "vuex";

import { getItemInfo } from "@/apis/home";
import { getStrFromJsonStr, isImageUrl, getProjectDb } from "@/utils";
import { getOrderDetail, getUserCoupon } from "@/apis";
import ans from "@/utils/ans";
import errorImage from "@/assets/images/coupondefault.png";

export default {
  components: {
    QrcodeVue,
    Barcode,
  },
  data() {
    return {
      info: {},
      coupon_format: "",
      showEmpty: false,
      coupon_code: "",
      loading: false,
      status: "",
      payInfo: {},
      order: {},
    };
  },
  computed: {
    ...mapState(["namespace"]),
  },
  async mounted() {
    const { item_code, coupon_code, coupon_format, order_number, status } =
      this.$route.query;
    if (!order_number) {
      this.showEmpty = true;
      return;
    }
    this.loading = true;
    const loading = this.$loading();
    try {
      await this.fetchOrder();
      const res = await getItemInfo({
        item_code,
        benefit_code: this.order.type,
        project_db: getProjectDb(this.namespace),
      });
      this.coupon_code = coupon_code;
      this.coupon_format = coupon_format;
      this.info = res;
      this.status = status;
      this.info.logo_url =
        getStrFromJsonStr(res.special_info, "coupon_logo_url") || errorImage;
      this.info.tips = getStrFromJsonStr(res.special_info, "tips");
    } finally {
      loading.clear();
      this.loading = false;
    }
  },
  methods: {
    toUse() {
      ans.track("coupon_use_click");

      if (isImageUrl(this.payInfo.coupon_code)) {
        this.$router.push(
          `/openimage?url=${encodeURIComponent(this.payInfo.coupon_code)}`
        );
        return;
      }
      // h5内部跳转，无需在链接上面拼接token
      location.href = this.payInfo.coupon_code;
    },
    copyCode(code) {
      copy(code);
    },
    async fetchOrder() {
      const { order_number } = this.$route.query;
      const order = await getOrderDetail({ order_number });
      const orderItem = order.data;
      this.order = orderItem;
      if (orderItem) {
        this.payInfo = JSON.parse(orderItem.pay_info);
        this.payInfo.coupon_enable = orderItem.coupon_enable;
      }
    },
    markUsed() {
      const { order_number } = this.$route.query;

      this.$confirm({
        message: "标记为已使用后，可在“卡券-已使用”中查看",
        confirmButtonText: "继续标记",
      }).then(async () => {
        const loading = this.$loading();
        try {
          const res = await getUserCoupon({
            status: "used",
            is_save: "T",
            order_number,
          });
          this.status = res.data.status;
          this.$toast("标记成功");
        } finally {
          loading.clear();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.coupon-detail {
  box-sizing: border-box;
  padding-top: 1px;
  min-height: 100vh;
  padding: 40px 15px 20px;
  background: linear-gradient(180deg, #eec99c 0%, #fff5e7 100%);
}

.h40 {
  height: 40px;
}
.showcode-main {
  background-color: #fff;
  border-radius: 8px;
  position: relative;
  padding: 1px 0;
}

.showcode-status-icon {
  position: absolute;
  width: 88px;
  height: 88px;
  right: 10px;
  top: 40px;
}

.showcode-logo {
  position: absolute;
  width: 54px;
  height: 54px;
  background-color: #fff;
  border-radius: 50%;
  overflow: hidden;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  /deep/ img {
    width: 44px;
    height: 44px;
    display: block;
    margin: 5px 0 0 5px;
  }
}

.showcode-img {
  margin: 0 auto 8px;
  text-align: center;
}

.showcode-name {
  text-align: center;
  font-size: 14px;
  color: #333;
  font-weight: bold;
}

.height80 {
  height: 40px;
}

.showcode-code {
  text-align: center;
  font-size: 12px;
  color: #999;
  margin-top: 4px;
  padding: 0 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.showcode-expired {
  font-size: 12px;
  color: #999;
  margin-top: 10px;
  text-align: center;
}

.showcode-tips {
  text-align: center;
  font-size: 12px;
  margin-top: 16px;
  color: #999;
}

.showcode-line {
  border-bottom: 1px dashed #ccc;
  position: relative;
  margin-top: 20px;
}

.semicircle {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  background: linear-gradient(to bottom, #f3d6b2, #f3d7b4);
}

.semicircle2 {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  background: linear-gradient(to bottom, #f1d4b1, #f3d7b4);
}

.showcode-explain {
  margin: 20px;
}

.showcode-explain-title {
  text-align: center;
  font-weight: bold;
  color: #333;
  font-size: 16px;
}

.showcode-explain-content {
  font-size: 12px;
  color: #666;
  margin-top: 15px;
  min-height: 150px;
  white-space: pre-wrap;
  line-height: 1.5;
}

.h10 {
  height: 10px;
}

.code-btn-container {
  background-color: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  padding: 12px 24px 20px;
  right: 0;
}

.code-btn {
  border: 1px solid #d9dbe0;
  box-sizing: border-box;
  line-height: 48px;
  height: 48px;
  border-radius: 9999px;
  text-align: center;
  font-size: 16px;
  color: #333;
  &:last-child {
    margin-left: 12px;
  }
  &.code-btn-primary {
    background: linear-gradient(115deg, #dfbd94 0%, #ffe8c8 100%);
    color: #6c3e0c;
    border: none;
  }
  flex: 1;
}

.h50 {
  height: 80px;
}

.coupon-main {
  margin: 0 16px;
  background: #fffbf5;
  border-radius: 8px;
  text-align: center;
  padding: 24px 16px;

  &.coupon-main-qr_code,
  &.coupon-main-bar_code,
  &.coupon-main-qr_bar_code {
    padding: 0;
    background: transparent;
    margin: -10px 0 0 0;
    .coupon-name {
      font-size: 14px;
      margin-bottom: 2px;
    }
    .coupon-bar-code {
      font-size: 12px;
      color: #999;
      margin-bottom: 12px;
    }
  }

  .coupon-expired {
    font-size: 12px;
    color: #999;
    line-height: 17px;
  }
  .copy {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url("~@/assets/images/copy.png") center center / 16px no-repeat;
    vertical-align: middle;
  }
}

.coupon-main .coupon-name {
  font-size: 20px;
  color: #333;
  font-weight: bold;
  line-height: 28px;
  margin-bottom: 8px;
}

.coupon-main .coupon-name2 {
  font-size: 20px;
  color: #333;
  margin-top: 34px;
  font-weight: bold;
  margin-bottom: 34px;
}

.coupon-main .coupon-btn {
  width: 255px;
  height: 45px;
  line-height: 45px;
  border-radius: 45px;
  background-color: #1a1a1a;
  font-size: 18px;
  color: #fff3da;
  margin: 16px auto 0;
}

.coupon-main .coupon-code {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
  color: #333;
  font-weight: bold;
  word-break: break-all;
  .coupon-code-item {
    & + & {
      margin-top: 10px;
    }
  }
  &.coupon-code-kami {
    text-align: left;
    font-size: 14px;
  }
}

.coupon-pending {
  text-align: center;
  font-size: 16px;
  color: #666;
}
</style>
