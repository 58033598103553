<template>
  <div class="outside" :class="{ isMember: pageType === 'member' }">
    <TabsHeader
      @onTabChange="onTabChange"
      :styleType="styleType"
      v-if="pageType !== 'member'"
      :activeCard="activeCard"
    />
    <div class="page-wrap">
      <Visitor
        v-if="!loading"
        :pageInfo="pageInfo"
        :activeCard="activeCard"
        :styleType="styleType"
        :pageType="pageType"
        :canUseBenefit="canUseBenefit"
        :cardInfo="cardInfo"
        :handleDraw="handleDraw"
        :packageInfo="packageInfo"
      />
    </div>
    <MemberCenter
      v-if="isMember"
      :cardInfo="cardInfo"
      @changeCard="changeMemberCard"
    />
    <Footer
      :pageType="pageType"
      :styleType="styleType"
      :activeCard="activeCard"
      :packageInfo="packageInfo"
    />
    <van-overlay :show="loading" class="loading">
      <div class="loading">
        <van-loading />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import qs from "qs";
import copy from "copy-to-clipboard";

import {
  getIndexCategory,
  getIndexClassList,
  queryH5JumpUrl,
  getPackageList,
  getMutilCard,
} from "@/apis/product";
import { getSkuByBenefitType } from "@/apis";
import { storage } from "@/utils";
import ans from "@/utils/ans";

import Visitor from "./visitor";
import Footer from "./components/Footer";
import MemberCenter from "./components/memberCenter";
import TabsHeader from "./components/TabsHeader";

export default {
  name: "licheng",
  components: {
    Visitor,
    MemberCenter,
    TabsHeader,
    Footer,
  },

  data() {
    return {
      pageInfo: [],
      styleType: false,
      activeCard: "licheng3",
      loading: false,
      pageType: "visitor",
      cardInfo: [], // 已开通卡信息
      canUseBenefit: false,
      packageInfo: {},
    };
  },

  computed: {
    ...mapState(["isMember", "namespace", "userInfo", "token", "source"]),
  },

  watch: {
    $route: {
      handler: function (val) {
        const { page_type } = val.query;
        page_type === "member"
          ? (this.canUseBenefit = true)
          : (this.canUseBenefit = false);
      },
      immediate: true,
    },
  },

  async mounted() {
    this.initStyle();
    this.initData();
    this.fetchOtherCard();
    this.fetchPackage();
  },

  methods: {
    ...mapMutations(["changeState"]),
    ...mapActions(["fetchNamespace", "fetchClientConfigList"]),
    // 切换卡
    async onTabChange(value) {
      storage.setItem("namespace", value);
      this.loading = true;
      this.activeCard = value;
      await this.fetchNamespace(value);
      await this.fetchClientConfigList(value);
      await this.initData(value);
      await this.fetchPackage();
      this.loading = false;
    },

    changeMemberCard(cardNamespace, cardToken) {
      storage.setItem("token", cardToken);
      storage.setItem("ptoken", cardToken);
      this.changeState({
        token: cardToken,
        ptoken: cardToken,
        namespace: cardNamespace,
      });
      this.onTabChange(cardNamespace);
      this.$store.dispatch("fetchUserInfo");
      this.canUseBenefit = true;
      this.$router.push({
        query: {
          ...this.$route.query,
          user_token: cardToken,
          page_type: "member",
          namespace: cardNamespace,
        },
      });
    },

    // 获取页面数据（前台分类及SPU）
    async initData(value) {
      try {
        const { data: categoriesData } = await getIndexCategory({
          namespace: value || this.namespace,
          opened_page: "visitor",
          source: "default",
        });
        const { data: productData } = await getIndexClassList({
          title: categoriesData.map((v) => v.name).join(","),
          opened_page: "visitor",
          namespace: value || this.namespace,
        });
        const pageInfo = categoriesData.map((value) => ({
          ...value,
          productInfo: productData[value.name] || [],
        }));
        this.pageInfo = pageInfo;
      } catch (e) {
        console.log(e);
      }
    },

    async initStyle() {
      const { style_type, page_type, namespace } = this.$route.query;
      this.styleType = style_type === "true";
      this.pageType = page_type || "visitor";
      this.activeCard = namespace;
      storage.setItem("namespace", namespace);
      this.changeState({ namespace });
    },

    async fetchOtherCard() {
      if (!this.token) return;
      try {
        const { data } = await getMutilCard();
        this.cardInfo = data;
        const checkIsMember = !!data.filter(
          (item) => item.namespace === this.namespace
        )[0];
        const isMemberPage = this.$route.query.page_type === "member";
        if (checkIsMember && isMemberPage) {
          this.canUseBenefit = true;
        } else {
          this.canUseBenefit = false;
        }
      } catch (e) {
        console.log(e);
      }
    },

    async handleDraw(item) {
      try {
        const { data } = await getSkuByBenefitType({
          benefit_type: item.product_code,
          source: this.source,
          namespace: this.activeCard,
        });
        const benefit = data[0];
        if (benefit.top_up_type !== 5) {
          this.$router.push(`benefitDetail?benefit_type=${item.product_code}`);
        }
        if (benefit.top_up_type === 5) {
          let jumpUrl =
            benefit.activation_page || benefit.partner_jump_url || "";

          if (!jumpUrl) {
            const url = await queryH5JumpUrl({
              user_id: this.userInfo.id,
              generate_h5_code: benefit.generate_h5,
              spu_id: benefit.generate_h5_spu_id,
              phone_number: benefit.user_phone,
            });
            jumpUrl = url.data;
          }
          this.$confirm({
            message: `请点击复制链接，打开外部浏览器使用`,
            showCancelButton: false,
            confirmButtonText: "复制链接",
          }).then(() => {
            ans.track("licheng_free_benefit_copy", {
              namespace: this.namespace,
              source: this.source,
              benefit_type: item.product_code,
            });
            copy(jumpUrl);
            this.$toast("使用链接已复制，请打开外部浏览器使用");
          });
        }
      } catch (e) {
        console.log(e);
      }
    },

    async fetchPackage() {
      const { data } = await getPackageList({
        namespace: this.activeCard,
        source: "hasPackage",
      });
      this.packageInfo = data[0];
    },
  },
};
</script>

<style scoped lang="less">
.outside {
  background: rgba(238, 240, 243, 1);
  min-height: 100vh;
  box-sizing: border-box;
}

.page-wrap {
  padding: 0 11px 20px 11px;
  border-radius: 0 0 16px 16px;
  margin-bottom: 70px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.isMember {
  padding-top: 20px;
}
</style>

