import Vue from 'vue';
import Layout from '../layout/Layout.vue';
import VueRouter from 'vue-router';
import store from '../store';
import business from './business';
import pagj from './pagj';
import xiaopai from './xiaopai';
import { newHotel } from './hotel';
import buffet from './buffet';
import qs from 'qs';
import redirect from './redirect';
import { Toast } from 'vant';
import { storage } from '@/utils';
import afterMarket from './afterMarket';
import offline from './offline';
import h5Benefit from './h5Benefit';
import { getOnceToken } from '@/apis';
import pickBy from 'lodash.pickby';
import promotion from './promotion';
import ans from '@/utils/ans';

Vue.use(VueRouter);

const createRouter = () => new VueRouter({
  routes: [{
    path: '/payRedirect',
    name: 'payRedirect',
    component: require('@/views/payRedirect').default,
    meta: {
      title: '加载中',
      pageName: 'payRedirect',
      pageData: ['namespace'],
      page: 'payRedirect',

    }
  },
  {
    path: '/',
    component: Layout,
    name: 'layout',
    redirect: '/index',
    children: [
      ...redirect,
      {
        path: '/index',
        name: 'index',
        component: require('@/views/index').default,
        meta: {
          title: '权益列表浏览',
          pageName: '权益列表浏览',
          pageData: ['namespace'],
          page: 'page_benefit_list',
        }
      },
      {
        path: '/cashBack',
        name: 'cashBack',
        component: require('@/views/cashBack').default,
        meta: {
          title: '下单返现',
          pageName: '下单返现页',
          pageData: ['namespace'],
          page: 'page_cashBack',

        }
      },
      {
        path: '/cashOut',
        name: 'cashOut',
        component: require('@/views/cashOut').default,
        meta: {
          title: '提现',
          pageName: '提现页',
          pageData: ['namespace'],
          page: 'page_cashOut',

        }
      },
      {
        path: '/cashOutRecord',
        name: 'cashOutRecord',
        component: require('@/views/cashOutRecord').default,
        meta: {
          title: '提现记录',
          pageName: '提现记录页',
          pageData: ['namespace'],
          page: 'page_cashOutRecord',

        }
      },
      {
        path: '/luckDraw',
        name: 'luckDraw',
        component: require('@/views/luckDraw').default,
        meta: {
          title: '抽奖活动',
          pageName: '抽奖',
          pageData: ['namespace'],
          page: 'page_blindbox'
        }
      },
      {
        path: '/seckill',
        name: 'seckill',
        component: require('@/views/seckill').default,
        meta: {
          title: '限时抢购',
          pageName: '秒杀活动页',
          pageData: ['namespace'],
          page: 'page_sekill',
        }
      },
      {
        path: '/mdetail',
        name: 'mdetail',
        component: require('@/views/mdetail').default,
        meta: {
          title: '商品详情',
          page: 'page_sekillskuid',
        }
      },
      {
        path: '/takeaway',
        name: 'takeaway',
        component: require('@/views/takeaway').default,
        meta: {
          title: '外卖红包天天领',
          pageName: '外卖返现',
          pageData: ['namespace'],
          page: 'page_takeaway',
        }
      },
      {
        path: '/dossen',
        name: 'dossen',
        component: require('@/views/dossen').default,
        meta: {
          title: '东呈会籍匹配',
          pageName: '东呈会籍匹配',
          pageData: ['namespace'],
          page: 'page_dossen',

        }
      },
      {
        path: '/belle',
        name: 'belle',
        component: require('@/views/belle').default,
        meta: {
          title: '百丽会籍匹配',
          pageName: '百丽会籍匹配',
          pageData: ['namespace'],
          page: 'page_belle',

        }
      },
      {
        path: '/refundList',
        name: 'refundList',
        component: require('@/views/refundList').default,
        meta: {
          title: '我的订单',
          pageName: '退款列表页',
          pageData: ['namespace'],
          page: 'page_refundList',

        }
      },
      {
        path: '/refundForm',
        name: 'refundForm',
        component: require('@/views/refundForm').default,
        meta: {
          title: '请选择取消原因',
          pageName: '退款表单页',
          pageData: ['namespace'],
          page: 'page_refundForm',

        }
      },
      {
        path: '/refundDetail',
        name: 'refundDetail',
        component: require('@/views/refundDetail').default,
        meta: {
          title: '退款申请',
          pageName: '退款申请',
          pageData: ['namespace'],
          page: 'page_refundDetail',

        }
      },
      {
        path: '/refundResult',
        name: 'refundResult',
        component: require('@/views/refundResult').default,
        meta: {
          title: '退款申请',
          pageName: '退款申请结果页',
          pageData: ['namespace'],
          page: 'page_refundResult',

        }
      },
      {
        path: '/refundApply',
        name: 'refundApply',
        component: require('@/views/refundApply').default,
        meta: {
          title: '申请退款',
          pageName: '申请退款页',
          pageData: ['namespace'],
          page: 'page_refundApply',
        }
      }, {
        path: '/order',
        name: 'order',
        component: require('@/views/order').default,
        meta: {
          title: '订单',
          pageName: '订单',
          pageData: ['namespace'],
          page: 'page_order_list',

        }
      }, {
        path: '/order/activity',
        name: 'activityOrder',
        component: require('@/views/order/activity').default,
        meta: {
          title: '活动订单',
          pageName: '活动订单',
          pageData: ['namespace'],
          page: 'page_activityOrder',

        }
      }, {
        path: '/orderDetail',
        name: 'orderDetail',
        component: require('@/views/orderDetail').default,
        meta: {
          title: '订单详情',
          pageName: '订单详情',
          page: 'page_order',
        }
      }, {
        path: '/openimage',
        name: 'openimage',
        component: require('@/views/openImage').default,
        meta: {
          title: '券码',
          pageName: '券码',
          pageData: ['namespace'],
          page: 'page_coupon',
        }
      }, {
        path: '/code',
        name: 'code',
        component: require('@/views/code').default,
        meta: {
          title: '券码',
          pageName: '券码',
          pageData: ['namespace'],
          page: 'page_coupon',
        }
      },
      {
        path: '/package',
        name: 'package',
        component: require('@/views/package').default,
        meta: {
          title: '选择套餐',
          pageName: '选择套餐',
          pageData: ['namespace'],
          page: 'page_package',

        }
      },
      {
        path: '/hotel/city',
        name: 'hotelCity',
        component: require('@/views/hotelCity').default,
        meta: {
          title: '选择城市',
          pageName: '选择城市',
          pageData: ['namespace'],
          page: 'page_city_list',

        }
      }, {
        path: '/hotel/cancelOrder',
        name: 'hotelCancelOrder',
        component: require('@/views/hotelCancelOrder').default,
        meta: {
          title: '取消酒店订单',
          pageName: '取消酒店订单',
          pageData: ['namespace'],
          page: 'page_hotelcancelOrder',

        }
      },

      {
        path: '/help',
        name: 'help',
        component: require('@/views/help').default,
        meta: {
          title: '帮助中心',
          pageName: '帮助中心',
          pageData: ['namespace'],
          page: 'page_help_center',

        }
      },
      {
        path: '/service',
        name: 'service',
        component: require('@/views/service').default,
        meta: {
          title: '问题咨询',
          pageName: '问题咨询',
          pageData: ['namespace'],
          page: 'page_qa',

        }
      },
      {
        path: '/centification',
        name: 'centification',
        component: require('@/views/centification').default,
        meta: {
          title: '实名认证',
          pageName: '实名认证',
          pageData: ['namespace'],
          page: 'page_centification',
        }
      },
      {
        path: '/couponDetail',
        name: 'couponDetail',
        component: require('@/views/couponDetail').default,
        meta: {
          title: '卡券详情',
          pageName: '卡券详情',
          pageData: ['namespace'],
          page: 'page_couponDetail',
        }
      },

      {
        path: '/pay',
        name: 'pay',
        component: require('@/views/pay').default,
        meta: {
          title: '支付',
          pageName: '支付页',
          pageData: ['namespace'],
          page: 'page_pay',

        }
      },
      {
        path: '/payResult',
        component: require('@/views/payResult').default,
        meta: {
          title: '支付结果',
          pageName: '权益支付结果页',
          pageData: ['namespace'],
          page: 'page_pay_result',

        }
      },

      {
        path: '/benefitDetail',
        name: 'benefitDetail',
        component: require('@/views/benefitDetail').default,
        meta: {
          title: '权益详情',
          page: 'page_benefit',

        }
      },
      {
        path: '/keep',
        name: 'keep',
        component: require('@/views/keep').default,
        meta: {
          title: 'GoGo大会员',
          pageName: '未开通页',
          pageData: ['namespace'],
          page: 'page_keep',
        }
      },
      {
        path: '/quanmin',
        name: 'quanmin',
        component: require('@/views/quanmin').default,
        meta: {
          pageData: ['namespace'],
          page: 'page_quanmin',
        }
      },
      {
        path: '/redeem',
        name: 'redeem',
        component: require('@/views/redeem').default,
        meta: {
          title: '兑换会员',
          pageData: ['namespace'],
          page: 'page_redeem',
        }
      },
      {
        path: '/dc-member',
        name: 'dc-redeem',
        component: require('@/views/index/dongcheng/index.vue').default,
        meta: {
          title: '兑换会员',
          pageData: ['namespace'],
          page: 'page_redeem',
        }
      },
      {
        path: '/common-redeem',
        name: 'common-redeem',
        component: require('@/views/index/commonPage/index.vue').default,
        meta: {
          title: '领取权益',
          pageData: ['namespace'],
          page: 'page_redeem',
        }
      },
      {
        path: '/redeemList',
        name: 'redeemList',
        component: require('@/views/redeemList').default,
        meta: {
          title: '兑换会员',
          pageData: ['namespace'],
          page: 'page_redeemList',
        }
      },
      {
        path: '/gfPckageList',
        name: 'gfPckageList',
        component: require('@/views/gfPckageList').default,
        meta: {
          title: '亲子权益',
          pageData: ['namespace'],
          page: 'page_gfPckageList',

        }
      },
      {
        path: '/shareBenefit',
        name: 'shareBenefit',
        component: require('@/views/shareBenefit').default,
        meta: {
          title: '分享权益',
          pageData: ['namespace'],
          page: 'page_shareBenefit',

        }
      },
      {
        path: '/onlineOrdering',
        name: 'onlineOrdering',
        component: require('@/views/onlineOrdering').default,
        meta: {
          title: '在线点餐',
          pageData: ['namespace'],
          page: 'page_onlineOrdering',

        }
      },
      {
        path: '/akBook',
        name: 'akBook',
        component: require('@/views/akBook').default,
        meta: {
          title: '体检预约',
          pageName: 'page_appintment',
          pageData: ['namespace'],
          page: 'page_akBook',

        }
      },
      {
        path: '/akConfirm',
        name: 'akConfirm',
        component: require('@/views/akConfirm').default,
        meta: {
          title: '确认信息',
          pageName: 'page_information',
          page: 'page_information',
        }
      },
      {
        path: '/akSelectCity',
        name: 'akSelectCity',
        component: require('@/views/akSelectCity').default,
        meta: {
          title: '选择城市',
          pageName: '选择城市',
          pageData: ['namespace'],
          page: 'page_akSelectCity',

        }
      },
      {
        path: '/cleanTeeth',
        name: 'cleanTeeth',
        component: require('@/views/cleanTeeth').default,
        meta: {
          title: '确认信息',
          pageName: 'page_cleanTeeth',
          pageData: ['namespace'],
          page: 'page_cleanTeeth',

        }
      },
      {
        path: '/complaints',
        name: 'complaints',
        component: require('@/views/complaints').default,
        meta: {
          title: '交易投诉',
          pageName: 'page_complaints',
          pageData: ['namespace'],
          page: 'page_complaints',

        }
      },
      {
        path: '/offlinePrize',
        name: 'offlinePrize',
        component: require('@/views/offlinePrize').default,
        meta: {
          title: '领取奖品',
          pageName: 'offlinePrize',
          pageData: ['namespace'],
          page: 'page_offlinePrize',
        }
      },
      {
        path: '/oil/list',
        name: 'oilList',
        component: require('@/views/oil/list').default,
        meta: {
          title: '油站列表',
          pageName: 'page_oil',
          pageData: ['namespace'],
          page: 'page_oil',

        }
      },
      {
        path: '/oil/detail',
        name: 'oilDetail',
        component: require('@/views/oil/detail').default,
        meta: {
          title: '油站详情',
          pageName: 'page_oil_detail',
          pageData: ['namespace'],
          page: 'page_oil_detail',
        },
      },
      {
        path: '/offlinePrize',
        name: 'offlinePrize',
        component: require('@/views/offlinePrize').default,
        meta: {
          title: '领取奖品',
          pageName: 'offlinePrize',
          pageData: ['namespace'],
          page: 'offlinePrize',

        }
      },
      {
        path: '/yzf',
        name: 'yzf',
        component: require('@/views/yzf').default,
        meta: {
          title: '兑换权益包',
          pageName: 'page_yzf_visitor',
          pageData: ['namespace'],
          page: 'page_yzf_visitor',
        }
      },
      {
        path: '/yzf/home',
        name: 'yzfHome',
        component: require('@/views/yzf/home').default,
        meta: {
          title: '兑换权益包',
          pageName: 'page_yzf_member',
          pageData: ['namespace'],
          page: 'page_yzf_member',
        }
      }
      , {
        path: '/coupon',
        name: 'coupon',
        component: require('@/views/business/coupon').default,
        meta: {
          title: '卡券',
          showTabbar: true,
          pageName: '卡券页',
          pageData: ['namespace'],
          page: 'page_coupon',


        },
      }, {
        path: '/instruction',
        name: 'instruction',
        component: require('@/views/instruction').default,
        meta: {
          title: '权益使用说明',
          showTabbar: true,
          pageName: '权益使用说明',
          pageData: ['namespace', 'item_code'],
          page: 'page_instruction',
        }
      }, {
        path: '/helpService',
        name: 'helpService',
        component: require('@/views/helpService').default,
        meta: {
          title: '客服中心',
          showTabbar: true,
          pageName: '客服中心',
          pageData: ['namespace'],
          page: 'page_helpService',
        }
      },
      {
        path: '/helpService/detail',
        name: 'helpServiceDetail',
        component: require('@/views/helpService/detail').default,
        meta: {
          title: '客服中心',
          showTabbar: true,
          pageName: '客服中心',
          pageData: ['namespace', 'id', 'group_id', 'order_number'],
          page: 'page_helpServiceDetail',
        }
      },
      {
        path: '/payRedirect',
        name: 'payRedirect',
        component: require('@/views/payRedirect').default,
        meta: {
          title: '加载中',
          pageName: 'payRedirect',
          pageData: ['namespace'],
          page: 'payRedirect',
        },
      },
      {
        path: '/unxpQuestion',
        name: "unxpQuestion",
        component: require('@/views/index/unxp/question.vue').default,
        meta: {
          title: '问大家',
          pageName: '问大家',
          pageData: ['namespace'],
          page: 'page_unxpQuestion',
          showTabbar: false,
        }
      },
      {
        path: '/travelCoupon',
        name: 'travelCoupon',
        component: require('@/views/travelCoupon/index.vue').default,
        meta: {
          title: '悦途出行权益',
          pageName: '悦途出行权益',
          pageData: ['namespace'],
          page: 'page_travelCoupon',
          showTabbar: false,
        }
      },
      { ...newHotel },
      { ...business },
      { ...pagj },
      { ...xiaopai },
      { ...buffet },
      ...promotion,
      ...afterMarket,
      ...offline,
      ...h5Benefit,
    ]
  }],
  scrollBehavior: () => ({ y: 0 }),
});

const router = createRouter();

let first = true;

async function init(to) {
  let {
    source,
    tenant,
    user_token: token,
    namespace,
    return_url,
    return_index_url,
    once,
    benefit_source,
    package_name,
    namespace_group,
    openid,
    login,
    s_t = "", //省钱卡token，用于订单页是否展示省钱卡订单入口
    all_project, //用于查询当前用户租户组的所有订单
  } = to.query;
  const local_namespace = storage.getItem("namespace");
  const local_namespace_group = storage.getItem("namespace_group");

  // 去掉缓存的租户
  if ((token || once) && !namespace && local_namespace && local_namespace.includes('_life')) {
    storage.removeItem("namespace");
    storage.removeItem("namespace_group");
    storage.removeItem("token");
    storage.removeItem("ptoken");
    store.commit('changeState', { namespace: undefined, token: undefined, ptoken: undefined, namespace_group: undefined });
  }
  // 如果链接上有token，且跟缓存的token不一致，则清除缓存的token
  // 有租户组，不清除
  if (
    namespace &&
    local_namespace &&
    local_namespace !== namespace
  ) {
    // 租户组存在，并且租户组跟缓存租户组不一致 才清空token
    if (!namespace_group || (namespace_group && namespace_group !== local_namespace_group)) {
      storage.removeItem("token");
      storage.removeItem("namespace");
      storage.removeItem("namespace_group");
      storage.removeItem("ptoken");
    }
  }
  // keep项目 once字段直接返回token
  if (once && once.length > 50) {
    token = once;
    once = "";
  }
  let user_token = token || storage.getItem("token") || "";
  // 避免页面刷新时重复调用接口
  if (once && localStorage.getItem("once") !== once) {
    const once_res = await getOnceToken({ once });
    localStorage.setItem("once", once);
    if (once_res.data.token) {
      user_token = once_res.data.token;
    }
  }
  const ptoken = storage.getItem("ptoken") || token || "";
  store.commit('changeState',
    pickBy({
      return_index_url,
      tenant,
      namespace,
      namespace_group,
      benefit_source,
      package_name,
      source,
      return_url,
      openid,
      token: user_token.replace("JWT ", ""),
      ptoken,
      s_t,
      all_project,
    },
      (v) => v !== undefined
    )
  );

  if (login === "off") {
    store.commit('changeState', {
      token: "",
      ptoken: "",
    });
  }

  await store.dispatch('fetchUserInfo');
  await store.dispatch('initProjectData')
  window.aplus_queue.push({
    action: "aplus.appendMetaInfo",
    arguments: [
      "globalproperty",
      {
        namespace: namespace || store.state.namespace,
        source: source || store.state.source,
        utm_channel: source || store.state.source,
      },
    ],
  });
  store.commit('changeState', {
    logining: false,
  });
}

// 全局路由拦截
router.beforeEach(async (to, from, next) => {
  if (first) {
    first = false;
    await init(to)
    next({ ...to, replace: true });
    return
  }
  Toast.clear()
  store.commit('changeBusinessState', {
    isShowTabbar: !!to.meta.showTabbar
  });
  store.commit('changePagjState', {
    isShowTabbar: !!to.meta.showTabbar
  });
  if (to.path === '/index') {
    document.title = Vue.prototype.$getConfigValue('INDEX_TITLE');
  } else if (to.meta?.title) {
    document.title = to.meta?.title;
  }

  // 新版帮助中心
  if ((to.path === '/service' || to.path === '/help') && Vue.prototype.$getConfigValue("SHOW_NEW_SERVICE_HELP")) {
    next({
      path: '/helpService',
      query: to.query
    })
    return
  }

  if (to.path === '/activity') {
    const { activityName, ...rest } = to.query;
    const params = qs.stringify({
      ...rest,
    }, { encode: false });
    next(`/${activityName}?${params}`)
    return;
  }
  const params = qs.parse(location.href.split('?')[1]);
  if (params.namespace === 'pingan_gj' && !storage.getItem('pagj_redirect') && to.path !== '/pagj/redirect') {
    next(`/pagj/redirect?${qs.stringify({
      ...params,
      redirect: to.fullPath
    })}`);
    return;
  }
  next();
});

router.afterEach((to) => {
  ans.ansView(to)
});

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

export default router;
