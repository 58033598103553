<template>
  <div class="luck-draw-body">
    <van-image
      class="luck-draw-bg"
      :src="activityInfo.special_info.header_img"
    />
    <div class="hit-egg">
      <div class="hit-egg-content hit-egg-rotate">
        <div
          class="hit-egg-item"
          v-for="(item, index) in fudais"
          :key="index"
          @click="drawHandleThrottle(index)"
        >
          <img
            class="edd-item-image"
            :src="item.select ? item.img2 : item.img"
          />
        </div>
      </div>
    </div>
    <div class="draw-btn-group">
      <div class="draw-start-btn" @click="drawHandleThrottle()">
        {{ activityInfo.special_info.start_draw_text || "开始抽奖" }}
        {{ remainingTimes > 0 ? `X${remainingTimes}` : "" }}
      </div>
    </div>
    <section class="loops-swipe">
      <div class="swipe-gift"></div>
      <van-swipe
        class="swipe"
        :autoplay="3000"
        :show-indicators="false"
        height="27px"
        :vertical="true"
      >
        <van-swipe-item v-for="(item, idx) in loops" :key="idx">
          {{ item.text }}
        </van-swipe-item>
      </van-swipe>
    </section>
    <Dialog
      v-model="showDialog"
      :activityReward="activityReward"
      :type="type"
      :openPrizePopup="openPrizePopup"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import throttle from "lodash.throttle";
import Dialog from "./Dialog";
import Vue from "vue";
import { queryLoopData } from "@/apis/home";
import store from "@/store";
const vm = Vue.prototype;
export default defineComponent({
  inheritAttrs: false,

  components: {
    Dialog,
  },
  props: {
    activityInfo: Object,
    activityReward: Object,
    beforeDraw: Function,
    openPrizePopup: Function,
    afterDraw: Function,
    type: String,
    remainingTimes: Number,
  },
  setup(props) {
    const loops = ref([]);
    const showDialog = ref(false);
    const fudais = ref(
      Array.from({ length: 3 }).map(() => ({
        img: "https://common-h5-dev.xiangshuheika.com/static/upload/202501162e6cd1dae4857.png",
        img2: "https://common-h5-dev.xiangshuheika.com/static/upload/202501161945885c84f2f.png",
        select: false,
      }))
    );
    onMounted(async () => {
      const res = await queryLoopData({
        activity_type: store.state.namespace + "-fudai",
      });
      loops.value = res.data.data_list;
    });
    const drawHandle = async (index) => {
      fudais.value = fudais.value.map((item) => ({ ...item, select: false }));
      const res = await props.beforeDraw(true);
      if (!res) {
        return;
      }
      fudais.value[index ?? Math.floor(Math.random() * 3)].select = true;
      const miss_tips = props.activityInfo.special_info.miss_tips;
      if (res.status === 6 && miss_tips) {
        // 未中奖
        vm.$toast(miss_tips);
      } else {
        showDialog.value = true;
      }

      await props.afterDraw();
    };
    const drawHandleThrottle = throttle(drawHandle, 3000, { trailing: false });

    return {
      drawHandleThrottle,
      showDialog,
      loops,
      fudais,
    };
  },
});
</script>

<style lang="less" scoped>
.loops-swipe {
  height: 47px;
  padding: 10px 13px;
  box-sizing: border-box;
  background: #fbdfb0;
  border-radius: 8px;
  margin: 80px 15px 26px;
  display: flex;
}
.swipe {
  height: 27px;
}

.van-swipe-item {
  line-height: 27px;
  color: #dc2d10;
  font-size: 12px;
}

.swipe-gift {
  width: 27px;
  height: 27px;
  background: url(https://common-h5-dev.xiangshuheika.com/static/upload/2025011661652d5160fce.png)
    no-repeat;
  background-size: 100%;
  margin-right: 12px;
}

.hit-egg {
  height: 150px;
  perspective: 800px;
  position: relative;
  position: absolute;
  bottom: 194px;
}

.hit-egg-content {
  backface-visibility: visible;
  transform-style: preserve-3d;
  transform: rotateX(90deg);
  position: absolute;
  margin: 0 auto;
  width: 260px;
  height: 150px;
  animation: spinBox 10s linear infinite;
  top: 0;
  left: 60px;
  // transform: rotateX(90deg) rotate(0deg);
}

.hit-egg-item {
  transform-style: preserve-3d;
  position: absolute;
  backface-visibility: visible;
  &:first-of-type {
    margin-top: 20px;
    transform: rotate(90deg) translateX(30px) rotateX(-90deg) rotateY(90deg);
  }
  &:nth-of-type(2) {
    margin-left: 110px;
    margin-top: -72px;
    transform: rotate(210deg) translateX(30px) rotateX(-90deg) rotateY(210deg);
  }
  &:nth-of-type(3) {
    margin-left: 130px;
    margin-top: 60px;
    transform: rotate(330deg) translateX(30px) rotateX(-90deg) rotateY(330deg);
  }
  img {
    animation: spinEgg 10s linear infinite;
    transform-origin: center;
    width: 120px;
    height: 160px;
    display: block;
  }
}

@keyframes spinEgg {
  0% {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(1turn);
  }
}

@keyframes spinBox {
  0% {
    transform: rotateX(90deg) rotate(0deg);
  }

  to {
    transform: rotateX(90deg) rotate(1turn);
  }
}
</style>
